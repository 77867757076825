import React from "react";
import { object } from "prop-types";
import { graphql } from "gatsby";

import {
  refactorDate,
  makeLinkFromName,
  makePostsLinkDate,
} from "shared/functions";
import { TextContent } from "components/TextContent";
import { BlogEntry } from "components/BlogEntry";
import { Layout } from "components/Layout";
import { Navbar } from "components/Navbar";

export default function Categories({
  data: { allMdx },
  location,
  pageContext,
}) {
  const posts = allMdx.edges;
  return (
    <Layout
      location={location}
      title={pageContext.category + " - www.srebniak.pl"}
    >
      <TextContent title={pageContext.category} isTitleBig>
        {posts.map(({ node }) => (
          <BlogEntry
            key={node.id}
            date={refactorDate(node.frontmatter.date)}
            title={node.frontmatter.title}
            author={node.frontmatter.author}
            heroImage={node.frontmatter.heroImage}
            description={node.frontmatter.description}
            categories={node.frontmatter.categories}
            path={"/" + makePostsLinkDate(node.frontmatter.date) + node.slug}
          />
        ))}
        <Navbar
          currentPage={pageContext.currentPage}
          pageNumber={pageContext.categoriesNumPages}
          link={
            pageContext.subcategoryOf
              ? `/kategoria/${
                  pageContext.subcategoryOf + makeLinkFromName(pageContext.link)
                }`
              : `/kategoria/${makeLinkFromName(pageContext.link)}`
          }
        />
      </TextContent>
    </Layout>
  );
}

Categories.propTypes = {
  data: object.isRequired,
  mdx: object,
  location: object.isRequired,
  pageContext: object.isRequired,
};

export const categoriesQuery = graphql`
  query CategoriesQuery($skip: Int!, $limit: Int!, $category: String) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        frontmatter: {
          categories: { elemMatch: { name: { in: [$category] } } }
        }
      }
    ) {
      edges {
        node {
          slug
          id
          frontmatter {
            title
            date
            author {
              name
              link
            }
            categories {
              name
              subcategoryOf
              link
            }
            description
            heroImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
